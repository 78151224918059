<template>
  <router-view />
</template>

<script>
  export default {
    name: ''
  }
</script>

<style lang='scss' scoped>

</style>
